<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-toolbar>
          <v-btn icon @click="$router.back()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title> Rediger bruger </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn>
            Gem
            <v-icon right>mdi-content-save-outline</v-icon>
          </v-btn>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card :loading="loading">
          <v-card-text>
            <v-form>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="user.fullName"
                    label="Navn"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="user.email"
                    label="E-mail"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="8">
        <v-card>
          <v-card-title>
            Roller
            <v-spacer></v-spacer>
            <v-btn small @click="onAddRoleDialogOpen">
              Tildel rolle
              <v-icon right>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <template v-for="roleCategory in user.roleCategories">
              <td
                v-for="(role, rIndex) in roleCategory.roles"
                :key="role.id"
                :style="rIndex==0 ? 'border-left: 2px solid black;' : null "
              >
                <PersonRoleEdit
                  :id="role.membership ? role.membership.id : null"
                  :person-id="user.id"
                  :role-id="role.id"
                  @saved="search"
                />
              </td>
            </template>

            <v-data-table
              :headers="roleHeaders"
              :items="user.roles"
            >
              <template #[`item.createdOn`]="{ value }">
                {{ new Date(value).toLocaleDateString() }}
              </template>
              <template #[`item.validFrom`]="{ value }">
                {{ new Date(value).toLocaleDateString() }}
              </template>
              <template #[`item.validTo`]="{ value }">
                {{ value ? new Date(value).toLocaleDateString() : '' }}
              </template>
              <template #[`item.actions`]="{ item, index }">
                <v-btn
                  icon
                  :loading="removingRoleIndex === index"
                  @click="removeRole(item, index)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title> Rettigheder </v-card-title>
          <v-card-text>
            <v-chip
              v-for="(item, index) in sortedPermissions"
              :key="index"
              label
              class="mr-2 mb-2"
            >
              {{ item }}
            </v-chip>

            <div v-if="!sortedPermissions.length">
              Ingen rettigheder
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title> Ansvar </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="responsibilityHeaders"
              :items="user.responsibilities"
            >
              <template #[`item.createdOn`]="{ value }">
                {{ new Date(value).toLocaleDateString() }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Lokationer
            <v-spacer></v-spacer>
            <div class="d-flex" style="gap: 1rem">
              <v-btn small @click="showAllLocations = !showAllLocations">
                {{ showAllLocations ? 'Vis kun aktive' : 'Vis alle' }}
              </v-btn>
              <v-btn
                small
                :disabled="!user.locations.some(x => x.isValid)"
                @click="onDeleteAllPersonLocation"
              >
                Slet alle
                <v-icon right>mdi-delete-sweep-outline</v-icon>
              </v-btn>
              <AddPersonLocationDialog :user="user" @success="getUser" />
            </div>
          </v-card-title>
          <v-card-subtitle>
            OBS! Hvis der tilføjes lokationer vil brugeren <strong>kun</strong> have adgang til de valgte lokationer. Hvis listen er tom vil brugeren have adgang til <strong>alle</strong> lokationer.
          </v-card-subtitle>

          <v-data-table
            :headers="locationHeaders"
            :items="user.locations.filter(x => this.showAllLocations || (!this.showAllLocations && x.isValid))"
            sort-by="customerLocationName"
            no-data-text="Brugeren har adgang til alle lokationer"
          >
            <template #[`item.validFrom`]="{ value }">
              {{ value | formatDateTime }}
            </template>
            <template #[`item.validTo`]="{ value }">
              {{ value | formatDateTime }}
            </template>
            <template #[`item.actions`]="{ item }">
              <div class="d-flex justify-end" style="gap: .5rem">
                <!-- <v-dialog max-width="500">
                  <template #activator="{ attrs, on }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title>Rediger lokationstilladelse</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <h3>Gyldig fra</h3>
                          <v-date-picker v-model="item.validFrom" elevation="5" width="auto" class="mt-2"></v-date-picker>
                        </v-col>
                      </v-row>
                      
                      <v-row>
                        <v-col>
                          <div>
                            <div class="d-flex justify-space-between align-center">
                              <h3>Gyldig til</h3>
                              <v-btn @click="item.validTo = undefined" :disabled="!item.validTo">Fjern</v-btn>
                            </div>
                            <v-date-picker v-model="item.validTo" elevation="5" width="auto" class="mt-2"></v-date-picker>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-dialog> -->
                
                <v-btn v-if="item.isValid" icon color="error" @click="onDeletePersonLocation(item.id)">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="addRoleDialog"
      max-width="800"
    >
      <v-card :loading="loadingRoles">
        <v-card-title> Tilføj rolle </v-card-title>
        <v-card-subtitle>
          Her kan du give brugeren en ny rolle.
        </v-card-subtitle>
        <v-card-text>
          <ul>
            <li>Hvilken rolle?</li>
            <ul>
              <li>Hvis brugeren har adgang til flere kunder skal man også vælge det. Måske først?</li>
              <li>Hvordan bestemmes hvilke roller man må give til hvem?</li>
            </ul>
            <li>Gyldig fra / til</li>
            <li>allocationNumber og allocatedHoursPerMonth</li>
          </ul>

          <v-list>
            <v-list-item
              v-for="(role, index) in assignableRoles"
              :key="role.id"
              two-line
            >
              <v-list-item-content>
                <v-list-item-title>{{ role.title }}</v-list-item-title>
                <v-list-item-subtitle>
                  <v-chip-group>
                    <v-chip
                      v-if="!role.responsibilities"
                      label
                      small
                    >
                      Ingen ansvar
                    </v-chip>
                    <template v-else>
                      <v-chip
                        v-for="(responsibility, index) in role.responsibilities"
                        :key="index"
                        label
                        small
                      >
                        {{ responsibility }}
                      </v-chip>
                    </template>
                  </v-chip-group>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  v-if="!user.roles.map(x => x.id).includes(role.id)"
                  :loading="assigningRoleIndex === index"
                  :disabled="assigningRoleIndex !== null && assigningRoleIndex !== index"
                  @click="assignRole(index)"
                >
                  Tilføj
                </v-btn>
                <v-btn
                  v-else
                  :loading="assigningRoleIndex === index"
                  :disabled="assigningRoleIndex !== null && assigningRoleIndex !== index"
                  @click="removeRole(index)"
                >
                  Fjern
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AddPersonLocationDialog from '@/components/AddPersonLocationDialog.vue';

export default {
  name: 'UserEdit',
  components: { AddPersonLocationDialog },
  data() {
    return {
      user: null,
      loading: true,
      loadingRoles: false,
      addRoleDialog: false,
      assigningRoleIndex: null,
      removingRoleIndex: null,
      assignableRoles: [],
      showAllLocations: false
    }
  },
  computed: {
    roleHeaders() {
      const result = [
        { text: 'Navn', value: 'title' },
        { text: 'Oprettet', value: 'createdOn' },
        { text: 'Gyldig fra', value: 'validFrom' },
        { text: 'Gyldig til', value: 'validTo' },
        { text: '', value: 'actions' }
      ]

      if (this.$store.getters.userHasMultipleCustomers) {
        result.splice(1, 0, { text: 'Kunde', value: 'customerTitle' })
      }

      return result
    },
    responsibilityHeaders() {
      const result = [
        { text: 'Navn', value: 'responsibilityTitle' },
        { text: 'Oprettet', value: 'createdOn' },
        { text: '', value: 'actions', align: 'end' },
      ]

      if (this.$store.getters.userHasMultipleCustomers) {
        result.splice(1, 0, { text: 'Kunde', value: 'customerTitle' })
      }

      return result
    },
    locationHeaders () {
      return [
        { text: 'Lokation', value: 'customerLocationName' },
        { text: 'Type', value: 'customerLocationType' },
        { text: 'Gyldig fra', value: 'validFrom' },
        { text: 'Gyldig til', value: 'validTo' },
        { text: '', value: 'actions', align: 'end' }
      ]
    },
    sortedPermissions() {
      return this.user.permissions.sort()
    }
  },
  methods: {
    getUser: async function() {
      const userId = this.$route.params.id

      try {
        const { data } = await this.$AuthService.api.get('user/' + userId)

        this.user = data.model
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false
      }
    },
    onAddRoleDialogOpen: async function() {
      this.addRoleDialog = true

      try {
        this.loadingRoles = true
        const { data } = await this.$AuthService.api.get(`organization/assignable-roles/${this.user.id}`)

        this.assignableRoles = data
      } catch (error) {
        console.error(error);
        this.$notifier.showDefaultError({});
      } finally {
        this.loadingRoles = false
      }
    },
    assignRole: async function(index) {
      this.assigningRoleIndex = index;

      try {
        const role = this.assignableRoles[index];

        if (!role) throw new Error("Could not find role at index " + index);

        const model = {
          personId: this.user.id,
          customerRoleId: role.id
        };

        await this.$AuthService.api.post('/organization/assign-role', model);

        this.$notifier.showSuccess({ message: 'Rollen blev tildelt' });
        this.getUser();
      } catch (error) {
        this.$notifier.showDefaultError();
        console.error(error);
      } finally {
        this.assigningRoleIndex = null;
      }
    },
    removeRole: async function(role, index) {
      this.removingRoleIndex = index

      try {
        const model = {
          personCustomerRoleId: role.personCustomerRoleId
        };

        await this.$AuthService.api.patch('/organization/role-validity', model);
        await this.getUser();

        this.$notifier.showSuccess({ message: 'Rollen blev opdateret' })
      } catch (error) {
        this.$notifier.showDefaultError();
        console.error(error);
      } finally {
        this.removingRoleIndex = null;
      }
    },
    onDeletePersonLocation: async function(personLocationId) {
      if (confirm('Er du sikker på du vil slette?')) {
        try {
          const model = {
            validTo: new Date().toISOString()
          }
          const { data } = await this.$AuthService.api.put('user/update-location/' + personLocationId, model)

          if (!data.success || data.errors.length) throw data.message

          this.$notifier.showSuccess({ message: 'Lokationen blev slette fra brugeren' });
          this.getUser();
        } catch (error) {
          console.error(error)
          this.$notifier.showError({ message: 'Kunne ikke slette' })
        }
      }
    },
    onDeleteAllPersonLocation: async function() {
      if (confirm('Er du sikker? Brugeren vil efter dette have adgang til alle lokationer.')) {
        const active = this.user.locations.filter(x => x.isValid)

        try {
          for (const item of active) {
            const model = {
              validTo: new Date().toISOString()
            }
  
            const { data } = await this.$AuthService.api.put('user/update-location/' + item.id, model)
  
            if (!data.success || data.errors.length) throw data.message
  
            this.$notifier.showSuccess({ message: 'Lokationen blev slette fra brugeren' });
          }
        } catch (error) {
          console.error(error)
          this.$notifier.showError({ message: 'Kunne ikke slette' })
        } finally {
          this.getUser();
        }
      }
    }
  },
  mounted: function() {
    this.getUser()
  }
}
</script>